import React, { Component } from 'react';
import {Link} from "gatsby";
import Image from "../../static/404.png"
import Logo from "../assets/images/logo-file.svg"

class f404 extends Component {
    render() {
        return (
                <div className="fourZeroFour">
                <img className="latest-logo" src={Logo} alt="Latest"/>
                <h1>Page not found!</h1>
                <p><Link to="/">Home page</Link></p>
                <img className="splash" src={Image} alt="404"/>

            </div>
        );
    }
}

export default f404;